class Digitalsignature {
    static async showFileDownload(id) {
        Utils.startWait();

        try {
            const response = await fetch(`/padrao/digitalsignature/showfiledownload/id/${id}`, {
                method: 'GET',
                headers: Utils.getHeadersText(),
                credentials: 'same-origin'
            });

            const data = await response.text();

            Utils.stopWait();

            Utils.openBlockUI(data, 'Visualizar arquivo');
        } catch (e) {
            // @ts-ignore
            alert(trata_erro2(e, '', ''));
        }
    }

    static async verify(id) {
        try {
            Utils.startWait();

            // @ts-ignore
            let data = await (new AssinadorAdapter()).validPdf(id, false);

            Utils.stopWait();

            Utils.openBlockUI(data, 'Verificar');
        } catch (e) {
            // @ts-ignore
            alert(trata_erro2(e, '', ''));
        }
    }

    static async verifyAlert(id) {
        let loadingResult = document.querySelector('.loading-result');

        if(!loadingResult){
            $('#result_verify').html('<img class="loading-result hide" src="/images/spinner.gif" alt="Loading">');
            loadingResult = document.querySelector('.loading-result');
        }

        try {
            loadingResult.classList.remove('hide');

            // @ts-ignore
            const assinadoAdapter = new AssinadorAdapter();

            const resultValidSignature = await assinadoAdapter.validPdf(id, false);

            if (typeof resultValidSignature === 'object') {
                alert(resultValidSignature.error);
                loadingResult.classList.add('hide');
            } else {
                $('#result_verify').html(resultValidSignature);
            }
        } catch (e) {
            // @ts-ignore
            alert(trata_erro2(e, '', ''));
            loadingResult.classList.add('hide');
        }
    }

    static async carimbarAlert(id) {
        let loadingResult = document.querySelector('.loading-result');

        if(!loadingResult){
            $('#result_verify').html('<img class="loading-result hide" src="/images/spinner.gif" alt="Loading">');
            loadingResult = document.querySelector('.loading-result');
        }
        try {
            loadingResult.classList.remove('hide');
            // @ts-ignore
            const assinadoAdapter = new AssinadorAdapter();

            const resultValidSignature = await assinadoAdapter.carimbardPdf(id, false);

            if (typeof resultValidSignature === 'object') {
                alert(resultValidSignature.error);
                loadingResult.classList.add('hide');
                return;
            }

            if(typeof resultValidSignature === 'string' && resultValidSignature.includes('Acesso não autorizado')){
                alert('Acesso não autorizado');
                loadingResult.classList.add('hide');
                return;
            }

            alert('Carimbado com sucesso!');

            this.verifyAlert(id);

        } catch (e) {
            // @ts-ignore
            alert(trata_erro2(e, '', ''));
            loadingResult.classList.add('hide');
        }
    }
}

$(document).ready(() => new Digitalsignature());
