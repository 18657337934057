/**
 * Classe responsável por representar os dados enviados ao assinador.
 * 
 * @type {DadosAssinatura}
 */
class DadosAssinatura {

    /**
     * Método construtor da classe.
     * 
     * @param {object} certificado
     * @param {number} modoOperacao
     * @param {number} tipoAssinatura
     * @param {number} politicaAssinatura
     * @param {String} senhaCertificado
     * @return {DadosAssinatura}
     */
    constructor(
            certificado,
            modoOperacao,
            tipoAssinatura,
            politicaAssinatura,
            cpfUserSign
            //senhaCertificado
            ) {
        this.certificado = certificado;
        this.modoOperacao = modoOperacao;
        this.tipoAssinatura = tipoAssinatura;
        this.politicaAssinatura = politicaAssinatura;
        // this.senhaCertificado = senhaCertificado;
        this.cpfUserSign = cpfUserSign;
    }

    /**
     * Método responsável por retornar uma lista de nomes de propriedades requieridas
     * 
     * @type string[]
     */
    get _propriedadesRequeridas() {
        return [
            'modoOperacao',
            'politicaAssinatura',
            // 'senhaCertificado',
            'certificado'
        ];
    }

    /**
     * Método responsável por validar os dados.
     * 
     * @returns {string}
     */
    isValid() {
        if (this._propriedadesRequeridas.some(propriedade => !this[propriedade])) {
            throw new Error('Existem propriedades requeridas vazias');
        }
    }
}
